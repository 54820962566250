import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  HOME_SECTION_IDENTIFIERS,
  HOME_WIDGET_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { selectDashboardData } from '../../state/DashboardData';
import { selectBaseUrl } from '../../state/UIState';
import { selectMenuData } from '../../state/MenuData';
import Section from './components/Section';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import Banner from './components/Banner';
import FavoritesSection from './components/FavoritesSection';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectChatEnabled, selectML35Config } from '../../state/MasterData';
import { selectUserChatGroups } from '../../state/ChatData';
import { uId } from '../../utils/ChatHandler';
import { selectEmployeeData } from '../../state/EmployeeData';
import { handleML35 } from '../../utils/featureNavigation';

const HomeScreen = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [chatUnreadCount, setChatUnreadCount] = useState(0);
  const employeeData = useSelector(selectEmployeeData);
  const userChatGroups = useSelector(selectUserChatGroups);
  const chatEnabled = useSelector(selectChatEnabled);
  const accessibilityData = useSelector(selectAccessibilityData);
  const menuData = useSelector(selectMenuData);
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const dashboardData = useSelector(selectDashboardData);
  const ML35Config = useSelector(selectML35Config);
  const [fevariteSections, setFevarioteSections] = useState([]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (baseUrl !== '') {
      dispatch({ type: ACTION_TYPES.GET_DASHBOARD, payload: { type: 'web' } });
      dispatch({ type: ACTION_TYPES.NOTIFICATION_COUNT, payload: {} });
    }
  }, [baseUrl, dispatch]);

  useEffect(() => {
    setFevarioteSections(
      menuData.data
        ? menuData.data.section_list.find(
            (section) => section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION
          ).features
        : []
    );
  }, [menuData.data]);

  useEffect(() => {
    if (chatEnabled && chatEnabled === true) {
      if (Object.keys(userChatGroups).length > 0) {
        let i = 0;
        const uid = uId(employeeData);
        Object.values(userChatGroups).forEach((value) => {
          if (value.members && uid in value.members) {
            if (value.members[uid].unread_group_count !== null) {
              i = i + value.members[uid].unread_group_count;
            }
          }
        });
        if (i > 0) {
          if (i > 99) {
            setChatUnreadCount('99+');
          } else {
            setChatUnreadCount(i);
          }
        } else {
          setChatUnreadCount(0);
        }
      }
    }
  }, [chatEnabled, employeeData, userChatGroups]);

  const renderSection = (data, index) => {
    if (
      (data.items.length && data.identifier !== HOME_SECTION_IDENTIFIERS.FOOTER) ||
      HOME_WIDGET_IDENTIFIERS.includes(data.identifier)
    ) {
      return (
        <Section
          key={index}
          sectionId={data.id}
          sectionTitle={data.title}
          sectionIdentifier={data.identifier}
          sectionItemType={data.template}
          sectionRoute={data.route}
          sectionItems={data.items}
        />
      );
    }
  };

  const renderHomeSections = () => {
    return dashboardData.map((section, index) => {
      return renderSection(section, index);
    });
  };

  const onPressChat = () => {
    history.push(NAVIGATION_ROUTES.CHAT);
  };

  return (
    <div>
      <div className="home-container">
        {chatEnabled && (
          <div
            onClick={onPressChat}
            className="d-flex common-cursor-pointer chat-floating-icon p-2">
            <div className="position-relative flex-grow-1 d-flex justify-content-center align-items-center">
              <img
                className="chat-icon"
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CHAT_WHITE,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
              />
              <div className="position-absolute chat-floating-icon-text-bubble d-flex justify-content-center align-items-center p-1">
                <p className="mb-0">{chatUnreadCount.toString()}</p>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid p-0">
          <Banner />
        </div>
        <div className="container">
          <FavoritesSection sectionItems={fevariteSections} />
          {ML35Config.isActive && (
            <img
              onClick={() => handleML35(true)}
              className={
                fevariteSections.length > 0
                  ? 'ml-35-home-banner'
                  : 'ml-35-home-banner-no-fev-sections'
              }
              src={ML35Config.webBannerImage}
            />
          )}
          {dashboardData.length ? (
            renderHomeSections()
          ) : (
            <div className="home-loader-outer-view">
              <div className="home-loader-outer-view mt-3">
                <ActivityLoader visible={true} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
