import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { saveEStoreTabData, selectEStoreTabData } from '../../state/TabData';
import { selectEStoreConfig, selectMicroStoreTabs } from '../../state/MasterData';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import StoreAccountTab from './tabs/StoreAccountTab';
import StoreHomeTab from './tabs/StoreHomeTab';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import { saveCartItemsCount, saveStoreAddress, selectCartItemsCount } from '../../state/EStoreData';
import { resetLoaderState, saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import { useHistory } from 'react-router';
import { logEvent, logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CARTS_MINE_ITEMS, CATEGORY_LIST, STORE_ADDRESS } from '../../services/ApiUrls';

const MicrostoreMainScreen = () => {
  const [categories, setCategories] = useState([]);
  const estoreTabdata = useSelector(selectEStoreTabData);
  const eStoreTabs = useSelector(selectMicroStoreTabs);
  const accessibilityData = useSelector(selectAccessibilityData);
  const cartItemsCount = useSelector(selectCartItemsCount);
  const dispatch = useDispatch();
  const pageId = useRef(1);
  const pageSize = 100;
  const history = useHistory();
  const estoreConfig = useSelector(selectEStoreConfig);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_MAIN_SCREEN,
      NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN
    );
  }, []);

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN);
  };

  const handleCategory = (item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.ESTORE_COLLECTION_CLICK,
      categoryId: item.id,
      categoryName: item.name,
    });
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN}/${item.id}`);
  };

  const renderCategory = (category, index) => {
    return (
      <div
        onClick={() => handleCategory(category)}
        key={index}
        className="estore-all-products-collection-item-main common-cursor-pointer">
        <img
          className="estore-collection-item-image"
          src={`${estoreConfig.category_base_url}${category.image}`}
        />
      </div>
    );
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'home':
        return (
          <div className="e-store-tabs-main">
            <div className="e-store-tab w-75">
              <StoreHomeTab />
            </div>
            <div className="estore-collection-container-accounts">
              <div className="estore-colloections-title">COLLECTION</div>
              {categories.map(renderCategory)}
            </div>
          </div>
        );
      case 'account':
        return (
          <div className="e-store-tabs-main-account">
            <div className="e-store-tab-account w-75">
              <StoreAccountTab />
            </div>
            <div className="estore-collection-container-accounts">
              <div className="estore-colloections-title">COLLECTION</div>
              {categories.map(renderCategory)}
            </div>
          </div>
        );
    }
    return <div>{tab.title}</div>;
  };

  useEffect(() => {
    const fatchCollections = async () => {
      try {
        const apiResponse = await apiRequestEStore(
          `${CATEGORY_LIST}?searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${pageId.current}`,
          REQUEST_TYPE.GET
        );

        if (Object.keys(apiResponse).length) {
          if (apiResponse.response && apiResponse.response.items?.length > 0) {
            const tempCategories = apiResponse.response.items?.map((item) => {
              const image =
                item.custom_attributes?.find((attr) => attr.attribute_code === 'image')?.value ??
                '';
              return { id: item.id, name: item.name, image };
            });
            const categoriesTemp = tempCategories.filter((item) => item.id !== 1);
            setCategories(categoriesTemp);
          }
        }
        dispatch(resetLoaderState());
      } catch (err) {
        dispatch(resetLoaderState());
      }
    };
    fatchCollections();
    fetchStoreAddress();
  }, [dispatch]);

  const fetchStoreAddress = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequestEStore(STORE_ADDRESS, REQUEST_TYPE.GET);
      if (apiResponse.response) {
        dispatch(saveStoreAddress(apiResponse.response[0]));
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  const updateCartCount = useCallback(async () => {
    try {
      dispatch(saveCartItemsCount(0));
      const apiResponse = await apiRequestUser(CARTS_MINE_ITEMS, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse).length && apiResponse.response?.length) {
        if (apiResponse.response) {
          let itemsCount = 0;
          apiResponse.response.map((item) => {
            itemsCount += item.qty;
          });
          dispatch(saveCartItemsCount(itemsCount));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    updateCartCount();
    if (estoreTabdata === '') {
      dispatch(saveEStoreTabData(eStoreTabs[0].title));
    }
  }, [estoreTabdata, eStoreTabs, dispatch, updateCartCount]);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        <div
          className="gcdo-icon-row mr-3"
          onClick={() => {
            logEvent(ANALYTICS_EVENT_TYPES.ESTORE_CART_CLICK, '', ANALYTICS_ITEM_NAMES.CART);
            history.push(NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN);
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CART_BLUE,
              ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
            )}
            className="header-icon-estore"
          />
          {cartItemsCount > 0 && <div className="cart-items-count-chip">{cartItemsCount}</div>}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <NavTabs
        activeKey={estoreTabdata}
        onSelect={(k) => {
          dispatch(saveEStoreTabData(k));
        }}>
        {eStoreTabs &&
          eStoreTabs
            .filter((tab) => tab.title !== 'Collection')
            .map((tab, index) => {
              return (
                <Tab key={`${index}`} eventKey={tab.title} title={tab.title}>
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
      </NavTabs>
    </div>
  );
};

export default MicrostoreMainScreen;
