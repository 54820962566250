import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEStoreConfig } from '../../../../state/MasterData';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequestEStore } from '../../../../services/Service';
import { GET_CHILDREN_OF_CONFIG_PRODUCT, PRODUCTS } from '../../../../services/ApiUrls';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import colors from '../../../../common/themes/Colors';
import HtmlView from '../../../../common/ui/html_view/HTMLView';

const HomeMicrostoreWidget = ({ setShowWidget }) => {
  const estoreConfig = useSelector(selectEStoreConfig);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const onPressItem = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_FEATURED_PRODUCT_CLICK,
      item.sku,
      ANALYTICS_ITEM_NAMES.FEATURED_PRODUCT
    );
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/${item.sku}`);
  };

  const fetchConfigurableProductPrice = useCallback(async (sku) => {
    try {
      const apiResponse = await apiRequestEStore(
        GET_CHILDREN_OF_CONFIG_PRODUCT(sku),
        REQUEST_TYPE.GET
      );

      if (Object.keys(apiResponse).length) {
        return apiResponse.response[0].price;
      } else {
        return 0;
      }
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const featuredResponse = await apiRequestEStore(
          `${PRODUCTS}?searchCriteria[filter_groups][0][filters][0][field]=featured&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=visibility&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=neq&searchCriteria[pageSize]=3&searchCriteria[currentPage]=1&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
          REQUEST_TYPE.GET
        );
        if (Object.keys(featuredResponse).length) {
          if (featuredResponse.response && featuredResponse.response.items?.length > 0) {
            const tempProducts = [];
            for (const item of featuredResponse.response.items) {
              const images = [];
              let thumbnail = '';
              item.media_gallery_entries.map((image) => {
                images.push({ type: image.media_type, file: image.file, thumbnail });
                if (image.types.includes('thumbnail')) {
                  thumbnail = image.file;
                }
              });
              const price =
                item.type_id === 'configurable'
                  ? await fetchConfigurableProductPrice(item.sku)
                  : item.price;
              const shortDescription = item.custom_attributes.find(
                (item) => item.attribute_code === 'short_description'
              )?.value;

              tempProducts.push({
                id: item.id,
                sku: item.sku,
                name: item.name,
                price: price,
                images,
                thumbnail,
                shortDescription,
              });
            }
            setProducts(tempProducts);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setShowWidget(false);
          }
        } else {
          setIsLoading(false);
          setShowWidget(false);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setShowWidget(false);
      }
    };
    fetch();
  }, [fetchConfigurableProductPrice]);

  const renderItem = (product, index) => {
    return (
      <div
        onClick={() => onPressItem(product)}
        key={index}
        className="col-12 col-sm-6 col-md-4 common-unstyled-achor common-cursor-pointer">
        <div className="position-relative pt-3">
          <img
            src={`${estoreConfig.image_base_url}${
              product.thumbnail ? product.thumbnail : product.images[0]?.file
            }`}
            className="estore-home-item-image"
          />
          <div className="estore-home-item-title-price">
            <div className="estore-product-name">{product.name}</div>
            <HtmlView html={product.shortDescription} htmlStyle="estore-product-description" />
            {product.price > 0 && (
              <div className="estore-product-name">{`\u20b9 ${product.price}`}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-0 col-sm-1" />
      <div className="col-12 col-sm-11 pl-3">
        <div className={'home-section-horizontal-scrollable'}>
          {isLoading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" style={{ color: colors.primary }} />
            </div>
          ) : (
            <div className={'d-flex flex-row flex-nowrap'}>
              {products.map((item, index) => renderItem(item, index))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeMicrostoreWidget;
