import { Modal } from 'react-bootstrap';
import React from 'react';
import HtmlView from '../html_view/HTMLView';

const CustomModal = ({
  visible,
  onClose = () => {},
  title,
  description,
  onClickSecondaryButton,
  onClickPrimaryButton,
  secondaryButtonTitle,
  primaryButtonTitle,
  onClick = () => {},
}) => {
  const renderBody = () => {
    return (
      <div>
        <div className="common-custom-modal-container">
          <div className="common-custom-modal-title">{title}</div>
          <div className="common-custom-modal-description">
            <HtmlView onClick={onClick} html={description} htmlStyle="estore-regular-text" />
          </div>
          <div className="common-custom-modal-buttons-block">
            {secondaryButtonTitle && (
              <div
                className="common-custom-modal-secondary-button"
                onClick={onClickSecondaryButton}>
                <div className="common-custom-modal-secondary-button-title">
                  {secondaryButtonTitle}
                </div>
              </div>
            )}
            <div className="common-custom-modal-primary-button" onClick={onClickPrimaryButton}>
              <div className="common-custom-modal-primary-button-title">{primaryButtonTitle}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal onHide={onClose} centered show={visible} aria-labelledby="contained-modal-title-vcenter">
      {renderBody()}
    </Modal>
  );
};

export default CustomModal;
