import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Picker from 'emoji-picker-react';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
} from '../../../constants/AppConstants';
import { useSelector } from 'react-redux';
import { selectProfinityFilterWarning } from '../../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getProfanityFilter, isEmptyText, showToast } from '../../../../utils/common';
import CustomButton from '../../../ui/custom_button/CustomButton';

const EditCommentBox = (props) => {
  const [showEmojiDiv, setShowEmojiDiv] = useState(false);
  const [commentText, setCommentText] = useState(props.commentText);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setCommentText(props.commentText);
  }, [props.commentText]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmojiDiv(false);
    }
  };

  const getTextAreaValue = (event) => {
    setCommentText(event.target.value);
    props.onChangehandler(event.target.value);
  };

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  const onEmojiClick = (event, emojiObject) => {
    setCommentText(commentText + emojiObject.emoji);
    props.onChangehandler(commentText + emojiObject.emoji);
    setShowEmojiDiv(false);
  };

  return (
    <div className={`common-edit-comment-box ${props.style}`}>
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      <div ref={wrapperRef} className="common-comment-emoji-icon-container">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.EMOJI_SMILE_ICON,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="common-comment-emoji-icon mr-3 common-cursor-pointer"
          onClick={() => {
            setShowEmojiDiv(true);
          }}
        />
        {showEmojiDiv && (
          <div className="common-edit-comment-emoji-div">
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
      <textarea
        name="description"
        id="description"
        placeholder="Type a comment..."
        spellCheck="false"
        value={commentText}
        onChange={getTextAreaValue}
        className="common-edit-comment-textarea"
      />
      <div className="d-flex flex-row justify-content-end">
        <CustomButton
          buttonStyle="common-submit-btn mt-2 common-edit-cancel-button-container"
          onClick={() => {
            props.callCancelFunction();
          }}>
          Cancel
        </CustomButton>
        <CustomButton
          buttonStyle="common-submit-btn mt-2 common-edit-update-button-container"
          onClick={() => {
            if (getProfanityFilter().isProfane(commentText)) {
              setShowWarningDialog(true);
            } else {
              const comment = commentText ? commentText : props.commentText;
              if (!isEmptyText(comment)) {
                showToast(ERROR_MESSAGES.EMPTY_TEXT);
              } else {
                props.callCommentFunction(comment);
              }
            }
          }}
          showLoader={props.showLoader}>
          Update
        </CustomButton>
      </div>
    </div>
  );
};

export default EditCommentBox;
